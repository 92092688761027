<template>
  <Switch
    :id="name"
    v-model="inputValue"
    :name="name"
    :class="[
      inputValue ? 'bg-cred disabled:bg-cred/35' : 'bg-gray-200',
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 disabled:cursor-default disabled:pointer-events-none',
    ]"
    :disabled="isDisabled"
    @click="handleChange"
  >
    <span
      aria-hidden="true"
      :class="[
        inputValue ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      ]"
    />
    <span class="hidden">{{ name }}</span>
  </Switch>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import { useField } from 'vee-validate'

const props = defineProps<{
  name: string
  isDisabled?: boolean
  value?: boolean
}>()

const modelValue = defineModel<boolean>()

const { value: inputValue, handleChange } = useField(props.name, undefined, {
  initialValue: modelValue,
  syncVModel: true,
})
</script>
