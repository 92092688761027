<template>
  <div
    class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
  >
    <div>
      <h3 class="text-lg font-medium leading-6 text-cblack">
        {{ $t('consentModal.heading') }}
      </h3>
      <div class="mt-2 space-y-3 text-sm text-gray-900">
        <p>
          {{ $t('consentModal.p1') }}
        </p>

        <i18n-t keypath="consentModal.p2" tag="p">
          <template #link>
            <NuxtLink
              to="/datenschutz"
              target="_blank"
              class="font-semibold underline text-cblack"
              >{{ $t('consentModal.link') }}</NuxtLink
            >
          </template>
        </i18n-t>
      </div>
    </div>

    <div v-if="isSettingsEnabled">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <div class="my-6 space-y-3 divide-y divide-gray-200">
          <Disclosure
            v-for="option in Object.keys(modelValue)"
            :key="option"
            v-slot="{ open }"
            as="div"
            class="pt-3"
          >
            <div class="flex justify-between text-base">
              <DisclosureButton
                class="flex items-center text-left text-gray-900"
              >
                <Icon
                  name="heroicons:chevron-down"
                  size="18"
                  :class="[
                    open ? 'rotate-0' : '-rotate-90',
                    'transform flex items-center mr-3',
                  ]"
                />

                {{ $t(`consentModal.options.${option}.title`) }}
              </DisclosureButton>

              <span v-if="option === 'necessary'">
                {{ $t('consentModal.alwaysActive') }}
              </span>
              <VSwitch v-else :value="modelValue[option]" :name="option" />
            </div>
            <DisclosurePanel as="dd" class="mt-2">
              <p class="text-sm text-gray-900">
                {{ $t(`consentModal.options.${option}.description`) }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 gap-3 mx-auto mt-5 max-w-50 sm:max-w-full sm:flex sm:justify-between sm:gap-3"
    >
      <VButton
        class="sm:w-1/3"
        :size="Size.sm"
        :variant="Variant.secondary"
        @click="onClickAcceptButton"
      >
        {{ $t('consentModal.acceptAll') }}
      </VButton>
      <VButton
        class="sm:w-1/3"
        :size="Size.sm"
        :variant="Variant.secondary"
        @click="onClickRejectButton"
      >
        {{ $t('consentModal.declineAll') }}
      </VButton>
      <VButton
        class="sm:w-1/3"
        :size="Size.sm"
        :variant="Variant.link"
        @click="onClickSettingsButton"
      >
        {{ $t(isSettingsEnabled ? 'consentModal.save' : 'consentModal.edit') }}
      </VButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Size, Variant } from '@/types/enums'

type ConsentModel = {
  necessary: boolean
  analytics: boolean
} & Record<string, boolean>

defineProps({
  modelValue: {
    type: Object as PropType<ConsentModel>,
    required: true,
  },
})

const emit = defineEmits(['accept-all', 'decline-all', 'save'])
const isSettingsEnabled = ref(false)

function onClickAcceptButton() {
  emit('accept-all')
}

function onClickRejectButton() {
  emit('decline-all')
}

function onClickSettingsButton() {
  if (isSettingsEnabled.value) {
    emit('save')
  } else {
    isSettingsEnabled.value = true
  }
}
</script>
