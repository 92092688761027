export enum Actions {
  new = 'new',
  update = 'update',
}

export enum AuthCookie {
  auth = 'auth',
  refresh = 'refresh',
}
export enum CompanyLegalType {
  gmbh = 'gmbh',
  gmbh_and_co = 'gmbh_and_co',
  ag = 'ag',
  ug = 'ug',
  einzelkaufmann = 'einzelkaufmann',
  andere = 'andere',
  keine_gewerbeanmeldung = 'keine_gewerbeanmeldung',
}

export enum FuelType {
  diesel = 'DIESEL',
  electric = 'ELECTRIC',
  gas = 'GAS',
  hydrogen = 'HYDROGEN',
  other = 'OTHER',
}

export enum ImageBuckets {
  offer_images = 'offer_images',
  template_images = 'template_images',
}

export enum OfferTemplateBuilderMode {
  template_create = 'template-create',
  template_edit = 'template-edit',
  offer_edit = 'offer-edit',
  offer_create = 'offer-create',
}

export enum OrderUrgency {
  information = 'information',
  concrete = 'concrete',
  urgent = 'urgent',
}

export enum OrderOfferStatus {
  viewed = 'viewed',
  answered = 'answered',
}

export enum OrderStatus {
  new = 'new',
  public = 'public',
  closed = 'closed',
}

export enum RentalPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum Size {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum StepStatus {
  current = 'current',
  upcoming = 'upcoming',
  completed = 'completed',
}

export enum Variant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  secondaryDark = 'secondaryDark',
  success = 'success',
  link = 'link',
  secondaryLink = 'secondaryLink',
  tertiaryLink = 'tertiaryLink',
  quaternaryLink = 'quaternaryLink',
}

export enum VehicleCategory {
  // agriculture = 'agriculture',
  body = 'body',
  container = 'container',
  lkw = 'lkw',
  semi_trailer = 'semi_trailer',
  trailer = 'trailer',
  transporter = 'transporter',
  truck = 'truck',
}

export enum VehicleAttributesMultiSelects {
  axis_number = 'axis_number',
  back_cover = 'back_cover',
  brakes = 'brakes',
  color = 'color',
  condition = 'condition',
  construction = 'construction',
  cooling = 'cooling',
  cooling_area_temperature_zone = 'cooling_area_temperature_zone',
  cooling_unit_drive = 'cooling_unit_drive',
  cover = 'cover',
  drive_type = 'drive_type',
  drum_drive = 'drum_drive',
  foldable_or_hydraulic = 'foldable_or_hydraulic',
  foldable_or_fixed = 'foldable_or_fixed',
  hydraulic_system = 'hydraulic_system',
  interior_lining = 'interior_lining',
  load_securing_system = 'load_securing_system',
  loading_area_material = 'loading_area_material',
  rear_wall_flap = 'rear_wall_flap',
  side_walls = 'side_walls',
  skip_lock = 'skip_lock',
  skip_lowering_tilt_angle = 'skip_lowering_tilt_angle',
  skip_pickup_system = 'skip_pickup_system',
  swap_body_type_suitable = 'swap_body_type_suitable',
  swap_body_locking_system = 'swap_body_locking_system',
  tailgate = 'tailgate',
  tipper_type = 'tipper_type',
  tipper_material = 'tipper_material',
  foot = 'foot',
  fuel_type = 'fuel_type',
  gearbox = 'gearbox',
  harvest_attachment = 'harvest_attachment',
  material = 'material',
  telescopic_arm_design = 'telescopic_arm_design',
  low_loader_type = 'low_loader_type',
  tarpaulin_system = 'tarpaulin_system',
  tilting_mechanism = 'tilting_mechanism',
  type_of_use = 'type_of_use',
  wall = 'wall',
}

export enum VehicleAttributesDates {
  last_main_inspection = 'last_main_inspection',
  last_security_inspection = 'last_security_inspection',
}

// MARK: Vehicle Subtypes
export enum AgricultureSubTypes {
  combine_harvester = 'combine_harvester',
  forage_harvester = 'forage_harvester',
  telehandler = 'telehandler',
  tractor = 'tractor',
  wheel_loader = 'wheel_loader',
}

export enum BodySubTypes {
  box_body = 'box_body',
  curtainsider_body = 'curtainsider_body',
  thermo_body = 'thermo_body',
}

export enum ContainerSubTypes {
  roll_off_container = 'roll_off_container',
  storage_container = 'storage_container',
  thermo_container = 'thermo_container',
}

export enum LkwSubTypes {
  bdf = 'bdf',
  box_lkw = 'box_lkw',
  concrete_mixer_lkw = 'concrete_mixer_lkw',
  crane_lkw = 'crane_lkw',
  curtainsider_lkw = 'curtainsider_lkw',
  flat_bed = 'flat_bed',
  roll_off_tipper = 'roll_off_tipper',
  skip_loader = 'skip_loader',
  swap_body_lkw = 'swap_body_lkw',
  thermo_lkw = 'thermo_lkw',
  three_way_tipper = 'three_way_tipper',
  tipper_lkw = 'tipper_lkw',
  tonner_3_5 = 'tonner_3_5',
  tonner_7_2 = 'tonner_7_2',
  tonner_7_5 = 'tonner_7_5',
  tonner_12 = 'tonner_12',
  tonner_16 = 'tonner_16',
}

export enum SemiTrailerSubTypes {
  box_semi_trailer = 'box_semi_trailer',
  concrete_mixer = 'concrete_mixer',
  container_chassis = 'container_chassis',
  curtainsider = 'curtainsider',
  low_loader = 'low_loader',
  mega_curtainsider = 'mega_curtainsider',
  moving_floor = 'moving_floor',
  reefer = 'reefer',
  tipper_construction = 'tipper_construction',
  tipper_trailer = 'tipper_trailer',
}

export enum TrailerSubTypes {
  box_trailer = 'box_trailer',
  container_chassis_trailer = 'container_chassis_trailer',
  curtainsider_trailer = 'curtainsider_trailer',
  swap_body_trailer = 'swap_body_trailer',
  thermo_trailer = 'thermo_trailer',
}

export enum TransporterSubTypes {
  box_transporter = 'box_transporter',
  curtainsider_transporter = 'curtainsider_transporter',
  high_roof = 'high_roof',
  thermo_transporter = 'thermo_transporter',
  van = 'van',
}

export enum TruckSubTypes {
  construction = 'construction',
  heavy_load = 'heavy_load',
  low_liner = 'low_liner',
  standard = 'standard',
  tilting_hydraulics = 'tilting_hydraulics',
}
